<script>
  export default {
    name: 'DarkMode',
    render() {
      return this.$scopedSlots.default();
    },

    watch: {
      '$store.state.settings.darkMode': {
        handler: (darkMode) =>
          document.documentElement.classList[darkMode ? 'add' : 'remove'](
            'dark-mode'
          ),
        immediate: true,
      },
    },
  };
</script>
