<template>
  <div class="error">
    <router-link :to="mailRoute">
      <Logo />
    </router-link>

    <div class="error__container">
      <img
        v-if="desktopMode"
        src="~@/assets/img/general-error.svg"
        :alt="imageAlt"
      />
      <img
        v-else
        src="~@/assets/img/general-error-mobile.svg"
        :alt="imageAlt"
      />
      <div class="error__container__content">
        <h1 v-translate>
          Oh no, this page
          <span class="error__container__italic">can’t be reached... </span>
        </h1>
        <p v-translate>Something went wrong. Try again later.</p>
      </div>
    </div>
  </div>
</template>

<script>
  import Logo from '@/components/Logo/Logo.vue';
  import { MAIL } from '@/router/named-routes';
  import { UI_MEDIAQUERIES } from '@/ui-mediaqueries';

  const desktopMediaQuery = window.matchMedia(
    UI_MEDIAQUERIES.SIDEBAR_NOT_COLLAPSED
  );

  export default {
    components: { Logo },
    name: 'Error',
    data() {
      return {
        desktopMode: desktopMediaQuery.matches,
      };
    },
    computed: {
      mailRoute() {
        return { name: MAIL };
      },
      imageAlt() {
        return this.$gettext(
          'Hand drawn illustration of a Tyrannosaurus rex with a paper bag over his head that has eyes and a smile drawn on the bag.'
        );
      },
    },
    created() {
      if (desktopMediaQuery.addEventListener) {
        desktopMediaQuery.addEventListener('change', this.handleResize);
      } else {
        desktopMediaQuery.addListener(this.handleResize);
      }
    },
    methods: {
      handleResize(ev) {
        this.desktopMode = ev.matches;
      },
    },
  };
</script>

<style src="./Error.scss" lang="scss"></style>
