
































  import Vue from 'vue';
  import QueryModal from '@/components/QueryModals/QueryModal.vue';

  export default Vue.extend({
    name: 'WelcomeModal',
    components: {
      QueryModal,
    },
    props: {
      customDomain: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      startNowButtonCallback() {
        (this.$refs.queryModal as any).closeModal();
      },
    },
    computed: {
      startNowButtonText(): string {
        return this.customDomain
          ? this.$gettext('Complete your domain setup')
          : this.$gettext('Go to your inbox');
      },
    },
  });
