/* eslint-disable no-console */

import { register } from 'register-service-worker';
import * as Sentry from '@sentry/vue';

export default function registerServiceWorker() {
  // Clear out all the caches used by the old service-worker, this can be
  // removed if we ever want to create a new service-worker again.
  if (window.caches) {
    window.caches
      .keys()
      .then((keys) => keys.forEach((key) => window.caches.delete(key)))
      .catch((err) => {
        if (err.name !== 'SecurityError') throw err;
      });
  }

  if (process.env.NODE_ENV === 'production') {
    let updateTimer;
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready() {
        console.log(
          'App is being served from cache by a service worker.\n' +
            'For more details, visit https://goo.gl/AFskqB'
        );
      },
      registered(registration) {
        console.log('Service worker has been registered.');
        // Check for a service-worker update every 60 minutes to keep users on
        // the latest version
        window.clearInterval(updateTimer);
        updateTimer = window.setInterval(async () => {
          try {
            await registration.update();
          } catch (err) {
            console.error(err);
            // Only send 1 in 1000 errors to Sentry
            if (Math.random() < 0.001) {
              Sentry.captureException(err);
            }
          }
        }, 60 * 60 * 1000);
      },
      cached() {
        console.log('Content has been cached for offline use.');
      },
      updatefound() {
        console.log('New content is downloading.');
      },
      updated() {
        console.log('New content is available; please refresh.');
      },
      offline() {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      },
      error(error) {
        console.error('Error during service worker registration:', error);
        window.clearInterval(updateTimer);
      },
    });
  }
}
