<template>
  <Modal
    v-bind="$attrs"
    :loading="changingPassword"
    ref="modal"
    @modalToggled="onModalToggled"
  >
    <template v-slot:toggle="{ toggle }">
      <slot name="toggle" :toggle="toggle" />
    </template>
    <template v-slot:header>
      <slot name="header" />
    </template>
    <template v-slot:content="{ toggle }">
      <form @submit.prevent="changePassword" novalidate>
        <PasswordField
          id="currentPassword"
          :label-text="currentPasswordText"
          v-model="currentPassword"
          :focused="true"
          :validate-input="validate"
          :validation-error-message="currentPasswordValidationErrorMessage"
          :show-custom-validity="Boolean(currentPasswordValidationErrorMessage)"
          autocomplete="current-password"
        />
        <PasswordField
          id="new-password"
          class="form-field"
          :label-text="newPasswordText"
          @scoreChange="onScoreChange"
          :focused="false"
          :user-inputs="userInputs"
          v-model="newPassword"
          :validate-input="validate"
          :validation-error-message="newPasswordValidationErrorMessage"
          :show-custom-validity="Boolean(newPasswordValidationErrorMessage)"
          :show-password-strength="!Boolean(newPasswordValidationErrorMessage)"
          :password-strength-error="passwordStrengthErrorMessage"
          autocomplete="new-password"
        />
        <PasswordField
          id="new-password-repeat"
          class="form-field"
          :label-text="repeatPasswordText"
          :focused="false"
          :user-inputs="userInputs"
          v-model="newPasswordRepeat"
          :validate-input="validate"
          :validation-error-message="newPasswordRepeatErrorMessage"
          :show-custom-validity="Boolean(newPasswordRepeatErrorMessage)"
          autocomplete="new-password"
        />
        <ModalConfirmActions
          :cancel-text="secondaryButtonText"
          @cancelClicked="
            toggle();
            $emit('closeModal');
          "
          :ok-text="confirmText ? confirmText : changePasswordText"
          :disabled="disableConfirmation"
        />
      </form>
    </template>
  </Modal>
</template>

<script>
  import Modal from '@/components/Modal';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions';
  import PasswordField from '@/components/PasswordField/PasswordField';

  export default {
    name: 'ChangePasswordModal',
    components: {
      PasswordField,
      Modal,
      ModalConfirmActions,
    },
    props: {
      changingPassword: {
        type: Boolean,
        default: false,
      },
      notificationMessage: {
        type: String,
        required: false,
        default: '',
      },
      currentPasswordText: {
        type: String,
        required: true,
      },
      confirmText: {
        type: String,
        required: false,
        default: '',
      },
      newPasswordText: {
        type: String,
        required: true,
      },
      repeatPasswordText: {
        type: String,
        required: true,
      },
      userInputs: {
        type: Array,
        default: () => [],
      },
      validationErrorMessages: {
        type: Object,
        default: () => ({}),
      },
      passwordStrengthErrorMessage: {
        type: String,
        required: false,
        default: '',
      },
      secondaryButtonText: {
        type: String,
        default() {
          return this.$gettext('Cancel');
        },
      },
    },
    data() {
      return {
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
        showRequirement: false,
        validate: false,
      };
    },
    computed: {
      changePasswordText() {
        return this.$gettext('Change password');
      },
      disableConfirmation() {
        return (
          this.newPassword === '' ||
          this.newPasswordRepeat === '' ||
          this.currentPassword === '' ||
          this.showRequirement
        );
      },
      currentPasswordValidationErrorMessage() {
        return this.validationErrorMessages.currentPassword || '';
      },
      newPasswordValidationErrorMessage() {
        return this.newPassword === this.currentPassword
          ? this.$gettext(
              "Your new password can't be the same as your old password."
            )
          : this.validationErrorMessages.newPassword || '';
      },
      newPasswordRepeatErrorMessage() {
        return this.newPassword !== this.newPasswordRepeat
          ? this.$gettext("Passwords don't match")
          : '';
      },
    },
    watch: {
      currentPassword() {
        this.$emit('resetValidation');
      },
    },
    methods: {
      onScoreChange({ passwordStrengthMeasured, passwordScore }) {
        this.showRequirement = passwordStrengthMeasured && passwordScore < 3;
      },
      onModalToggled(modalOpened) {
        if (!modalOpened) {
          this.currentPassword = '';
          this.newPassword = '';
          this.newPasswordRepeat = '';
          this.showRequirement = false;
          this.validate = false;
          this.$emit('resetValidation', true);
        }
      },
      toggleModal() {
        this.$refs.modal.toggleModal();
      },
      changePassword(ev) {
        this.validate = true;

        if (!ev.target.checkValidity()) {
          return;
        }

        this.$emit('changePassword', {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        });
      },
    },
  };
</script>
