




































































  import Vue from 'vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import RadioInput from '@/components/form/RadioInput.vue';
  import InputField from '@/components/form/InputField.vue';
  import api from '@/api';
  import RecoveryEmailForm from '@/components/RecoveryEmailForm/RecoveryEmailForm.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionRecoveryEmailModal',

    components: {
      Modal,
      ModalConfirmActions,
      RadioInput,
      InputField,
      RecoveryEmailForm,
    },

    data() {
      return {
        options: [
          {
            name: this.$gettext('Subscription manager'),
            value: 0,
          },
          {
            name: this.$gettext('My secondary email address'),
            value: 1,
          },
        ],
        selectedOption: 0,
        emailAddress: '',
        recoveryInformation: {} as RecoveryStatusResponseData,
        isValidated: false,
        validationError: null as number | null,
        showConfirmation: false,
        password: '',
        submittingRecoveryEmail: false,
      };
    },

    created() {
      this.getRecoveryStatus();
    },

    computed: {
      confirmText(): string {
        return this.$gettext('Submit');
      },

      labelText(): string {
        return this.$gettext('Recovery email address');
      },

      passwordLabelText(): string {
        return this.$gettext('Password');
      },
    },

    methods: {
      toggleOption(selectedValue: number) {
        this.selectedOption = selectedValue;
        this.emailAddress = '';
        if (selectedValue == 0) {
          this.emailAddress = this.recoveryInformation.recovery_address || '';
        }
      },

      getRecoveryStatus() {
        api.recovery.getRecoveryStatus().then((recoveryInformation) => {
          this.recoveryInformation = recoveryInformation;
          this.emailAddress = recoveryInformation.recovery_address || '';
        });
      },

      closeModal(event: boolean) {
        if (!event) {
          this.$emit('closeModal');
        }
      },
    },
  });
